import clsx from 'clsx';
import {
  OFFICE_LOCATION,
  DEVELOPMENT_EMAIL,
  DEVELOPMENT_PHONE_NUMBER,
  JOB_EMAIL,
  HOTLINE_PHONE_NUMBER,
  OFFICE_EMAIL,
  SUPPLY_EMAIL,
  SUPPLY_PHONE_NUMBER,
  MEDIA_EMAIL,
  ADVERTISING_EMAIL,
  FRANCHISE_PHONE_NUMBER,
} from 'const';
import { useNavigate } from 'react-router-dom';
import { FEButton } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { CopyButton, Map } from './components';
import style from './style.module.css';
import { IMG_IC_24_OK, IMG_IC_24_TG, IMG_IC_24_VK, IMG_IC_24_YT } from 'images';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { Helmet } from 'react-helmet';
import { $city } from 'domains/cartography';
import { $sitesMap } from 'domains/sitesMap';

const linkOptions = {
  target: '_blank',
  rel: 'noreferrer',
};

const ContactsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [city, sitesMap] = useUnit([$city, $sitesMap]);

  const is1440 = useMediaQuery(`(min-width: 1440px)`);

  return (
    <>
      {city && (
        <Helmet>
          <title>{`Контакты | Номера телефона и адрес офиса | СушиВесла ${city.name}`}</title>
          <meta
            name='description'
            content={`Телефоны и электронные почты для гостей, потенциальных партнёров и коллег. Пиши и звони нам по любому вопросу. Контакты СушиВесла в ${city.name}.`}
          />
        </Helmet>
      )}
      <div className={style.root}>
        <div className={style.breadCrumbs}>
          <span
            className='text-2'
            onClick={() => {
              navigate('/');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            {t('contacts.home')}
          </span>
          <span className='text-2'>/</span>
          <span className='text-2'> {t('contacts.title')}</span>
        </div>
        <h1 className={clsx(is1440 ? 'heading1' : 'heading2', style.title)}>
          {t('contacts.title')}
        </h1>

        <div className={style.contacts}>
          <div className={style.contactsList}>
            <div className={style.contactsListItem}>
              <p className='headline'>{t('contacts.contacts.hotline.label')}</p>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`tel:${HOTLINE_PHONE_NUMBER}`}
                >
                  {HOTLINE_PHONE_NUMBER}
                </a>
                <CopyButton toCopy={HOTLINE_PHONE_NUMBER} />
              </div>
              <p className={clsx('text-2', style.textMargin)}>
                {t('contacts.contacts.hotline.caption')}
              </p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                {t('contacts.contacts.hotline.message1')} <br />
                {t('contacts.contacts.hotline.message2')} <br />
                {t('contacts.contacts.hotline.message3')}
              </p>
            </div>

            <div className={style.contactsListItem}>
              <p className='headline'>
                {t('contacts.contacts.socialNetworks.label')}
              </p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                {t('contacts.contacts.socialNetworks.message')}
              </p>
              <div
                className={clsx(style.blockMargin, style.socialNetworksList)}
              >
                {sitesMap?.vk && (
                  <FEButton
                    className={style.socialNetworkButton}
                    onClick={() => window.open(sitesMap.vk, '_blank')}
                    type='secondary'
                  >
                    <img src={IMG_IC_24_VK} alt='' />
                  </FEButton>
                )}
                {sitesMap?.telegram && (
                  <FEButton
                    className={style.socialNetworkButton}
                    onClick={() => window.open(sitesMap.telegram, '_blank')}
                    type='secondary'
                  >
                    <img src={IMG_IC_24_TG} alt='' />
                  </FEButton>
                )}
                {sitesMap?.youtube && (
                  <FEButton
                    className={style.socialNetworkButton}
                    onClick={() => window.open(sitesMap.youtube, '_blank')}
                    type='secondary'
                  >
                    <img src={IMG_IC_24_YT} alt='' />
                  </FEButton>
                )}
                {sitesMap?.ok && (
                  <FEButton
                    className={style.socialNetworkButton}
                    onClick={() => window.open(sitesMap?.ok, '_blank')}
                    type='secondary'
                  >
                    <img src={IMG_IC_24_OK} alt='' />
                  </FEButton>
                )}
              </div>
            </div>

            {sitesMap?.job && (
              <div className={style.contactsListItem}>
                <p className='headline'>{t('contacts.contacts.job.label')}</p>
                <div className={clsx(style.textMargin, style.copyBlock)}>
                  <a
                    className={clsx('subhead', style.link)}
                    href={`mailto:${JOB_EMAIL}`}
                  >
                    {JOB_EMAIL}
                  </a>
                  <CopyButton toCopy={JOB_EMAIL} />
                </div>
                <p
                  className={clsx(
                    'text-2',
                    style.textMargin,
                    style.secondaryText,
                  )}
                >
                  {t('contacts.contacts.job.message1', {
                    companyName: 'СушиВесла',
                  })}{' '}
                  <br />
                  {t('contacts.contacts.job.message2')}{' '}
                  <a
                    {...linkOptions}
                    className={clsx('text-2', style.link)}
                    href={sitesMap.job}
                  >
                    {t('contacts.contacts.job.link')}
                  </a>
                  <br />
                  {t('contacts.contacts.job.message3')}
                </p>
                <FEButton
                  type='secondary'
                  className={clsx(style.blockMargin, style.vacanciesButton)}
                  onClick={() => window.open(sitesMap.job, '_blank')}
                  children={t('contacts.contacts.job.buttonText')}
                />
              </div>
            )}

            <div className={style.contactsListItem}>
              <p className='headline'>{t('contacts.contacts.rental.label')}</p>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`mailto:${DEVELOPMENT_EMAIL}`}
                >
                  {DEVELOPMENT_EMAIL}
                </a>
                <CopyButton toCopy={DEVELOPMENT_EMAIL} />
              </div>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`tel:${DEVELOPMENT_PHONE_NUMBER}`}
                >
                  {DEVELOPMENT_PHONE_NUMBER}
                </a>
                <CopyButton toCopy={DEVELOPMENT_PHONE_NUMBER} />
              </div>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                {t('contacts.contacts.rental.message')}
              </p>
            </div>

            <div className={style.contactsListItem}>
              <p className='headline'>{t('contacts.contacts.media.label')}</p>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`mailto:${MEDIA_EMAIL}`}
                >
                  {MEDIA_EMAIL}
                </a>
                <CopyButton toCopy={MEDIA_EMAIL} />
              </div>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                {t('contacts.contacts.media.message')}
              </p>
            </div>

            <div className={style.contactsListItem}>
              <p className='headline'>
                {t('contacts.contacts.advertising.label')}
              </p>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`mailto:${ADVERTISING_EMAIL}`}
                >
                  {ADVERTISING_EMAIL}
                </a>
                <CopyButton toCopy={ADVERTISING_EMAIL} />
              </div>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                {t('contacts.contacts.advertising.message1')} <br />
                {t('contacts.contacts.advertising.message2')}
              </p>
            </div>

            {sitesMap?.franchise && (
              <div className={style.contactsListItem}>
                <p className='headline'>
                  {t('contacts.contacts.franchise.label')}
                </p>
                <div className={clsx(style.textMargin, style.copyBlock)}>
                  <a
                    className={clsx('subhead', style.link)}
                    href={`tel:${FRANCHISE_PHONE_NUMBER}`}
                  >
                    {FRANCHISE_PHONE_NUMBER}
                  </a>
                  <CopyButton toCopy={FRANCHISE_PHONE_NUMBER} />
                </div>
                <p
                  className={clsx(
                    'text-2',
                    style.textMargin,
                    style.secondaryText,
                  )}
                >
                  {t('contacts.contacts.franchise.message1', {
                    companyName: 'СушиВесла',
                  })}{' '}
                  <a
                    {...linkOptions}
                    className={clsx('text-2', style.link)}
                    href={sitesMap.franchise}
                  >
                    {t('contacts.contacts.franchise.link')}
                  </a>
                  <br /> {t('contacts.contacts.franchise.message2')}
                </p>
              </div>
            )}

            <div className={style.contactsListItem}>
              <p className='headline'>{t('contacts.contacts.supply.label')}</p>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`mailto:${SUPPLY_EMAIL}`}
                >
                  {SUPPLY_EMAIL}
                </a>
                <CopyButton toCopy={SUPPLY_EMAIL} />
              </div>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`tel:${SUPPLY_PHONE_NUMBER}`}
                >
                  {SUPPLY_PHONE_NUMBER}
                </a>
                <CopyButton toCopy={SUPPLY_PHONE_NUMBER} />
              </div>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                {t('contacts.contacts.supply.message')}
              </p>
            </div>

            <div className={style.contactsListItem}>
              <p className='headline'>{t('contacts.contacts.office.label')}</p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                Волгоград, улица Маршала Чуйкова, 37
              </p>
              <div className={clsx(style.textMargin, style.copyBlock)}>
                <a
                  className={clsx('subhead', style.link)}
                  href={`mailto:${OFFICE_EMAIL}`}
                >
                  {OFFICE_EMAIL}
                </a>
                <CopyButton toCopy={OFFICE_EMAIL} />
              </div>
            </div>
          </div>
        </div>

        <Map
          className={style.map}
          center={OFFICE_LOCATION}
          officePosition={OFFICE_LOCATION}
        />

        <div className={style.legalInfo}>
          <h1 className={clsx(is1440 ? 'heading1' : 'heading2')}>
            {t('contacts.legalInformation.title')}
          </h1>
          <div className={style.legalInfoList}>
            <div className={style.legalInfoListItem}>
              <p className='headline'>
                {t('contacts.legalInformation.legalLabel')}
              </p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                ООО «УК СушиВесла»
              </p>
            </div>

            <div className={style.legalInfoListItem}>
              <p className='headline'>
                {t('contacts.legalInformation.legalAddressLabel')}
              </p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                400005, Волгоградская область, Волгоград, <br />
                ул. Им. Маршала Чуйкова, влд. 37, этаж 1, помещ. 20
              </p>
            </div>

            <div className={style.legalInfoListItem}>
              <p className='headline'>ОГРН</p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                1133443035770 от 27 декабря 2013 г.
              </p>
            </div>

            <div className={style.legalInfoListItem}>
              <p className='headline'>ИНН/КПП</p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                3444210862/344401001
              </p>
            </div>

            <div className={style.legalInfoListItem}>
              <p className='headline'>ОКПО</p>
              <p
                className={clsx(
                  'text-2',
                  style.textMargin,
                  style.secondaryText,
                )}
              >
                22396814
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsPage;
